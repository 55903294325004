import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {HomepageContent} from './homepage-content';

@Component({
    selector: 'homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomepageComponent implements OnInit, OnDestroy {
    public content: HomepageContent;
    public overlayBackground;
    private sub: Subscription;

    constructor(
       
    ) {}

    ngOnInit() {
        
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    
}
