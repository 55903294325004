import { AppConfig } from '@common/core/config/app-config';

export const PAICON_CONFIG: AppConfig = {
    assetsPrefix: 'client',
    navbar: {
        defaultColor: 'accent',
        defaultPosition: 'drive-navbar',
        dropdownItems: [
            {route: '/drive', name: 'My Files', icon: 'network-drive-custom'},
        ]
    },
    auth: {
        redirectUri: '/dashboard',
        adminRedirectUri: '/dashboard',
        color: 'primary',
    },
    demo: {
        email: null,
    },
    translations: {
        uploads_disk_driver_description: 'Where drive file uploads should be stored.',
    }
};
